import React from "react"
import { Heading, ExtendedTeaser } from "./style"
import { Grid, Row, Col } from "components/UI/Grid"
import { Box, Line, StyledLink } from "components/UI"
import { Spacer } from "components/UI/Spacer"
import { useStaticQuery, graphql } from "gatsby"

const LatestResources = ({ handpickedData, noMarginTop, heading, variant }) => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteSettings {
              pgResource {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        resources: allWpPost(limit: 3) {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
        pages: allWpPage(limit: 15) {
          nodes {
            title
            uri
            templateSlug
            featuredImage {
              node {
                sourceUrl
                srcSet
              }
            }
          }
        }
      }
    `
  )

  const resourcesPageURI = data.wp.siteOptions.siteSettings.pgResource.uri
  const isHandpicked = handpickedData?.items?.length > 0
  const latestResources = isHandpicked
    ? handpickedData?.items
    : data.resources.nodes

  if (latestResources.length)
    return (
      <Grid mainColumn>
        {!noMarginTop && <Spacer height={[40, 60, 95]} />}

        <Heading>{heading || "Latest Insights"}</Heading>
        <Spacer height={[25, 40]} />
        <Row xsGap={0} smGap={30} mdGap={32}>
          {latestResources?.slice(0, 3)?.map((teaser, index) => {
            const { categories, uri, title, featuredImage } = teaser || {}
            return (
              <Col
                xs={12}
                sm={4}
                md={4}
                xsAlignX="center"
                mdAlignX="start"
                key={Math.random() + index}
              >
                <ExtendedTeaser
                  tag={categories?.nodes[0]?.name}
                  link={uri}
                  title={title}
                  img={{ ...featuredImage?.node, aspectRatio: 1.5 }}
                />
              </Col>
            )
          })}
        </Row>
        <Line />
        <Box width="100%">
          <StyledLink
            to={isHandpicked ? "/case-studies" : resourcesPageURI}
            mt={[14, 35]}
            mb={[50, 0]}
            ml="auto"
          >
            View all
          </StyledLink>
        </Box>
      </Grid>
    )

  return <div />
}

export default LatestResources
