import styled from "styled-components"
import AvenueImg from "UI/AvenueImg"

export const WhiteTeaseImage = styled(AvenueImg)`
  width: 100%;
  flex-shrink: 0;
  position: relative;
  background: ${(props) => props.theme.navy};
  margin-bottom: 10px;
  object-fit: cover;
  &.avenueImg {
    padding-bottom: ${(props) => {
      return props.imgAspectRatio && `${(1 / props.imgAspectRatio) * 100}%`
    }};
    > div {
      padding-bottom: 0 !important;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
