import styled from "styled-components"
import { Box } from "UI"
import { device } from "breakpoints"

export const LandingTemplateContainer = styled.div``
export const FlexibleContentWrapper = styled(Box)`
  padding-top: 20px;

  p:first-of-type {
    font-size: 16px;
    color: #575a5e;
    @media ${device.desktop} {
      font-size: 18px;
    }
  }

  @media ${device.desktop} {
    padding-top: 60px;
  }
`
