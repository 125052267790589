import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"

import { Box, Text, StyledLink } from "UI"
import { WhiteTeaseImage } from "./styles"

export const FullWidthLink = styled(StyledLink)`
  position: static;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
  }
`
export const WhiteTeaserContainer = styled(Box)`
  border-bottom: 10px solid #fff;
  overflow: hidden;
  :hover {
    border-bottom: 10px solid #008cd1;
  }
`
export const WhiteTeaserDetails = styled(Box)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`
const WhiteTeaser = ({ title, excerpt, to, image }) => {
  return (
    <WhiteTeaserContainer
      boxShadow="0px 5px 35px rgba(0, 0, 0, 0.15)"
      borderRadius="4px"
      background="white"
      width="100%"
      height={[null, "100%"]}
      position="relative"
    >
      {image?.sourceUrl && (
        <WhiteTeaseImage fluid={image} imgAspectRatio={1.5} />
      )}
      <WhiteTeaserDetails p={[30]} pb={[27, null, 32]}>
        <Text as="h3" fontSize={[18, null, 24]} color="#008CD1" mb={[18]}>
          {title && parse(title)}
        </Text>
        <Text as="p" mb={[15, null, 20]}>
          {excerpt && parse(excerpt)}
        </Text>
        <FullWidthLink mt="auto" to={to || "/"}>
          Learn more
        </FullWidthLink>
      </WhiteTeaserDetails>
    </WhiteTeaserContainer>
  )
}

export default WhiteTeaser
